


































































































// Import ChromeLang from '@/components/chrome/Lang.vue'
import ChromeNav from '@/components/chrome/Nav.vue'
import MegaMenu from '@/components/chrome/MegaMenu.vue'

import { defineComponent, computed } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'chrome-header',
  components: {
    // ChromeLang,
    ChromeNav,
    MegaMenu,
  },

  setup(_props, ctx) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const isDesktop = computed(() => ctx?.parent!.$el.clientWidth >= 1024)

    return {
      ...useGetters(['currentLang']),
      isDesktop,
    }
  },
})
